import React from 'react';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import getServerSideTranslations from 'shared/utils/pages/getServerSideTranslations';
import { logger } from 'services/utils/logger';
import { Brand, ParsedUrlQuery } from 'shared/types';
import { formatParameter } from 'shared/utils/formatParameter';
import { isFeatureEnabled } from 'shared/utils/featureToggle';
import { TranslationKey, Product } from 'shared/constants';
import { FeatureToggles } from 'shared/constants/features';
import { titleCase } from 'shared/utils/titleCase';
import getTranslationKey from 'shared/utils/pages/getTranslationKey';
import Page from 'shared/components/Page';
import { useBrand } from 'shared/hooks/useBrand';
import Heros from 'features/Hero';
import DetailedComparator from 'features/detailed-comparator/components/DetailedComparator/DetailedComparator';
import { useRouter } from 'next/router';

const SUPPORTED_BRANDS = [Brand.FORD, Brand.VOLVO, Brand.AUDI, Brand.WAYLANDS, Brand.VOLKSWAGEN];

interface Props {
  title: string;
  description: string;
  query: ParsedUrlQuery;
  product: Product;
}

const DetailedComparatorPage: React.FC<Props> = ({ ...props }): React.ReactElement => {
  const { title, description } = props;
  const brand = useBrand();
  const { query } = useRouter();
  const { t: translate } = useTranslation(`${brand}.${TranslationKey.DETAILED_COMPARATOR}`);
  const Hero = Heros[brand];

  return (
    <Page pageID={Product.DETAILED_COMPARATOR} title={title} description={description} brand={brand}>
      <Hero
        pageId={Product.DETAILED_COMPARATOR}
        title={translate('hero.title')}
        subTitle={translate('hero.subtitle')}
      />
      <DetailedComparator {...props} query={query} />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ params, query, locale = 'en-GB' }) => {
  const brand = formatParameter(params?.brand);
  const detailedComparatorFeatureToggle = FeatureToggles.FEATURE_DETAILED_COMPARATOR;

  let pageTitle: string = '';
  const title: string = titleCase(brand);
  const description: string = title;

  if (!isFeatureEnabled(detailedComparatorFeatureToggle, Brand[brand.toUpperCase()])) {
    logger.warn(`[PAGE]: ${detailedComparatorFeatureToggle} is disabled for '${brand}'`);

    return {
      notFound: true,
    };
  }

  if (!SUPPORTED_BRANDS.includes(Brand[brand.toUpperCase()])) {
    logger.error(`[PAGE]: "${brand}" is not supported`);

    return {
      notFound: true,
    };
  }

  pageTitle = await getTranslationKey(
    locale,
    Brand[brand.toUpperCase()],
    'common',
    Product.DETAILED_COMPARATOR,
    '.meta.title'
  );

  return {
    props: {
      ...(await getServerSideTranslations(
        locale,
        [
          `${brand}.${TranslationKey.COMPARATOR}`,
          `${brand}.${TranslationKey.DETAILED_COMPARATOR}`,
          `${brand}.${TranslationKey.COMMON}`,
        ],
        Product.DETAILED_COMPARATOR
      )),
      title: `${title} - ${pageTitle}`,
      description: `${description} - ${pageTitle}`,
      query,
      product: Product.DETAILED_COMPARATOR,
    },
  };
};

export default DetailedComparatorPage;
